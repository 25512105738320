import $ from 'jquery';
import 'slick-carousel';
import 'slick-carousel/slick/slick.css';
import popup  from './libs/popup.js'
import Masonry from 'masonry-layout';
import jQueryBridget from 'jquery-bridget';
import imagesLoaded from 'imagesloaded';
jQueryBridget('masonry', Masonry, $);
jQueryBridget( 'imagesLoaded', imagesLoaded, $ );

const home = {};

home.sliderBanner = function(){
	$('.banner-home .slider-img').slick({
		arrows:false,
		dots:false,
		fade:true,
		asNavFor:'.banner-home .bot-slider',
		autoplay: true,
  	autoplaySpeed: 10000,
	});

	$('.banner-home .bot-slider').slick({
		arrows:false,
		dots:true,
		asNavFor:'.banner-home .slider-img',
	});
	
	$('.bot-slider').find('ul.slick-dots').wrap("<div class='slick-dots-container'></div>");
	
}

home.sliderPlanner = function (){
	$('.home.about .title-home .slider-text').slick({
		arrows:false,
		dots:false,
		autoplay: true,
  	autoplaySpeed: 3000,
	})
}

home.masonry = function(){
	
	const $container = $('#masonry-grid');
	$container.imagesLoaded(() => {
	  $container.masonry({
	    columnWidth: '.grid-sizer',
	    itemSelector: '.grid-item',
	    percentPosition: true,
	  });
	});

	$('.wrap-video .thumb-video .ic-play').click(function(){
        $(this).parents('.wrap-video').find('.wrap-yt').show();
        $(this).parents('.wrap-video').find('.wrap-yt').each(function() {
            var iframe = $(this).find('iframe');
            setTimeout(function() {
                iframe[0].src = iframe.attr('src').replace('?&autoplay=0', '?&autoplay=1');
            }, 100);
        });
    });
}

home.map = function(){
	$('.wrap-maps .grid-tabing a').on('click',function(e){
		e.preventDefault();
		const data = $(this).data('pin');
		$(this).parents('.wrap-maps').find('.box-drag figure').removeAttr('class').addClass(data)
		$(this).parents('.grid-tabing').find('a').removeClass('active')
    $(this).toggleClass('active')
	})
}


home.setcookie = (cname, cvalue, exdays) => {
  var d = new Date();
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
  var expires = "expires="+d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

home.getcookie = (cname) => {
  var name = cname + "=";
  var ca = document.cookie.split(';');
  for(var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

home.checkcookie = () => {
  var user = home.getcookie("subscribe");

  if (user != "") {
    
  } else {
    popup($).openpopup('#pop-subscribe')  
    home.setcookie("subscribe", "kotakita", 7);
  }   
}

home.cookieshome = () => {
  $('#pop-subscribe .close-popup').on('click',function(e){
        e.preventDefault()
        const idpopup = '#pop-subscribe'
        popup($).closepopup(idpopup)  
  });
}

home.init = function(){
	this.sliderBanner()
	this.masonry()
	this.map()
	this.sliderPlanner()
	this.checkcookie()
	this.cookieshome()
}

home.init()